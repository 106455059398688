/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Sunit's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Sunit Bail Portfolio",
    type: "website",
    url: "http://sunitbail.com/",
  },
};

//Home Page
const greeting = {
  title: "Sunit Bail",
  logo_name: "Sunit Bail",
  nickname: "layman_brother",
  subTitle: "An experienced developer with a background in web application development, always looking to diversify his repertoire to maximize technological impact and test its limits.",
  resumeLink: "https://drive.google.com/file/d/1NBuFrzDd_8SJYRx2YHkF0c-6yluXdxeW/view?usp=sharing",
  portfolio_repository: "https://github.com/bailsunit",
  githubProfile: "https://github.com/bailsunit",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/bailsunit",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/sunit-bail/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:sunitbail95@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#4285F4", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Yahoo",
    link: "mailto:sunitbail@yahoo.com",
    fontAwesomeIcon: "fa-yahoo", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#720e9e", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/sunit.bail.1/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/sunitbail/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Back End Development",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready applications optimized for performance, memory and extension",
        "⚡ Creating processes using automation tools to schedule key processes",
        "⚡ Capable of taking end-to-end ownership including configuration management, build and deployment",
      ],
      softwareSkills: [
        {
          skillName: "Java",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Spring",
          fontAwesomeClassname: "logos:spring-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Hibernate",
          fontAwesomeClassname: "logos:hibernate",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "SQL",
          fontAwesomeClassname: "carbon:sql",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Salesforce",
          fontAwesomeClassname: "logos:salesforce",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Maven",
          fontAwesomeClassname: "logos:maven",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Front End Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Creating application backend in Node and Express",
        "⚡ Migrating existing frontends using newer technologies",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Bootstrap5",
          fontAwesomeClassname: "logos:bootstrap",
          style: {
            backgroundColor: "transparent",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Axure",
          fontAwesomeClassname: "fluent-emoji:a-button-blood-type",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Moqups",
          fontAwesomeClassname: "emojione:circled-m",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Balsamiq",
          fontAwesomeClassname: "emojione-v1:b-button",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Leetcode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://leetcode.com/bails/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/bail_s",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Northeastern University",
      subtitle: "Master's in Software Engineering Systems",
      logo_path: "neu_logo.svg",
      alt_name: "NEU",
      duration: "2021 - 2022",
      descriptions: [
        "⚡ With a focus on practical backend programming, I have taken classes on Enterprise Software Design, specifically working with MVC frameworks and many implementations of the JPA interface, Software Patterns to understand the nuances of smart architecture and Database Management for optimized normalized data models. ",
        "⚡ To expand my knowledge of the front end world, I studied User Experience Design techniques and tools like Moqups, Balsamiq, Axure and Figma. I also took Web Design and gained familiarity in Bootstrap5, NodeJS, ExpressJS and React.",
        "⚡ I also served as a Senator with the Graduate Student Government, working with the Office of Student Conduct and Conflict Resolution in it's panel selection. Additionally, I worked with student groups seeking funding as a member of the Student Affairs committee.",
      ],
      website_link: "https://www.northeastern.edu/",
    },
    {
      title: "JSS Academy for Technical Education",
      subtitle: "B.E. in Computer Science and Engineering",
      logo_path: "jss_logo.png",
      alt_name: "JSSATE",
      duration: "2013 - 2017",
      descriptions: [
        "⚡ My introduction to software development started with courses on Data Structures and Algorithms, Computer Architecture, System Design, Operating Systems and a plethora of languages like Ruby on Rails, Assembly, PHP, C#, C, C++ among others.",
        "⚡ My focus during this time was on Core Java development and build tools Maven and Gradle. This is also when I first tasted CI/CD with Jenkins.",
        "⚡ I have a strong foundation in Software Engineering and Information Security from my time.",
        "⚡ I focussed on BigData for my thesis with an implementation of the MapReduce algorithm run on publicly available data in virtual environments specialized for their computing power.",
      ],
      website_link: "https://jssateb.ac.in/",
    }
  ],
};

const certifications = {
  certifications: [
    {
      title: "Transitioning from Waterfall to Agile Project Management",
      subtitle: "- Kelley O'Connell",
      logo_path: "agile2.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/5dc9c7d4cf6e179a8ee139c19bb404f3ba8fb6d61561d6d3e9deb2be1735183f?trk=share_certificate",
      alt_name: "Agile",
      color_code: "#808080",
    },
    {
      title: "Insights on Software Quality Engineering",
      subtitle: "- Aaron Dolberg",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/844ef5b9fd9197fa918ea9c9da14e932241864f86182176b058cd78ade0cd633?trk=share_certificate",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Git Essential Training",
      subtitle: "- Kevin Skoglund",
      logo_path: "git.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/6b9f20dec30a4f2549908ef501a29a426d0d3495ed90facbec266ecc4c18a16e?trk=share_certificate",
      alt_name: "Git",
      color_code: "#0C9D5899",
    },
    {
      title: "Java 8 Essential Training",
      subtitle: "- David Gassner",
      logo_path: "java.jpeg",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/d0655519d9e7143aa04311d24a6315165d8b8bc309c214a3ad667239b3927c03?trk=share_certificate",
      alt_name: "Java",
      color_code: "#1F70C199",
    },
    {
      title: "Learning Oracle Database 12c",
      subtitle: "- Bob Bryla",
      logo_path: "oracle.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/4670fb52f140ff9a0e011c43bd2339b0eec3060c09070eaa45d28e6ddb53bc77?trk=share_certificate",
      alt_name: "Oracle",
      color_code: "#D83B0199",
    },
    {
      title: "Installing and Running WordPress: Local by FlyWheel",
      subtitle: "- Morten Rand-Hendriksen",
      logo_path: "wordpress.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/bd2469e7066cf17eeea5852abcc899328a934e80e24563135c9b0289045a286e?trk=share_certificate",
      alt_name: "Wordpress",
      color_code: "#8C151599",
    },
    {
      title: "Java: Data Structures",
      subtitle: "- Peggy Fisher",
      logo_path: "datastructure.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/eafa1fbab2172d171f6ee3e5fa29e5a5c0b0e92e7664041f92fe674104e994dc?trk=share_certificate",
      alt_name: "Data Structures",
      color_code: "#1F70C199",
    },
    {
      title: "Finance Foundations",
      subtitle: "- Earl Stice and Jim Stice",
      logo_path: "finance.jpeg",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/041ce4d5a0c281f050d7f4ae6c2a824ac3167e2d8e8df1e80dece480820eb48d?trk=share_certificate",
      alt_name: "Finance",
      color_code: "#0C9D5899",
    },
    {
      title: "Java Platforms Compared: SE vs EE vs ME",
      subtitle: "- Peggy Fisher",
      logo_path: "platforms.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/1c5816e815249c6220b4b55b76734938e6d81f3d0f752e69d90dda49ba00f9fb?trk=share_certificate",
      alt_name: "platforms",
      color_code: "#00000099",
    },
    {
      title: "Java: Testing with JUnit",
      subtitle: "- Peggy Fisher",
      logo_path: "junit.jpeg",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/3ebb5afd6468f22b0ed4c238785882e5a7e084a0f5ecc1e90220f51be960a488?trk=share_certificate",
      alt_name: "Junit",
      color_code: "#2A73CC",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I come equipped with a work history primarily aligned with FinTech. I have a robust knowledge of the Derivatives market and the Insurance landscape, as well as the technical challenges associated with ensuring application strength and resilience. ",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Software Engineer",
          company: "Chicago Mercantile Exchange Group",
          company_url: "https://cmegroup.com/",
          logo_path: "cme-group.jpeg",
          duration: "August 2017 - December 2020",
          location: "Bengaluru, Karnataka, India",
          description:
          "I was involved with the migration of a legacy user interface to a React based framework, primarily as a backend developer. My responsibilities there required me to isolate the user interface code into a new module that could be eliminated once the new user interface was launched. As the project extended, I was also tasked with the formulation of ReSTful APIs, that would allow the newly developed REACT framework to communicate with the robust backend application. A regular exercise for me was to identify vulnerable components on our applications and find the most suitable alternative to it. I took complete ownership of the deployment process as laid out in the company’s policy. My efforts reduced deployment time by 43% for new release environments and by 34.5% in Production environments, largely by leveraging the capabilities of Splunk and App Dynamics. The applications were deployed on RedHat 7 servers that were configured using a Chef cookbook that I helped configure and update from time to time. Jenkins was the CI tool of choice for automated deployment, and I contributed to the ansible files written in Groovy. ",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Cloud Software Engineer",
          company: "New York Life R&D Lab",
          company_url: "https://www.newyorklife.com/",
          logo_path: "new-york-life-logo.jpeg",
          duration: "March 2022 - September 2022",
          location: "New York, New York, USA",
          description:
          "My responsibilities at NYL were towards a Springboot based Java application that was being built up to migrate from the existing Salesforce system, as a result I gained experience working with Sales Central, Workbench and associated tools and technologies. I developed ReSTful APIs as a bridge between the ReactJS based frontend and the Salesforce persistence layer, based primarily off of picklist values and reference types. ",
          color: "#ee3c26",
        }
      ],
    }
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects are primarily based on web applications hosted locally or on services like Heroku and Netlify. These include APIs and POCs and utilize public data. I'm working on deploying these on Cloud platforms to test their robustness.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "sunit.png",
    description:
      "I've been in my chosen field for over five years now. If I'm in a position to help you pick programming as a major, a hobby or as an occupation, feel free to holler at me at any of the links below.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://ashutoshhathidara.wordpress.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 8320758513",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
