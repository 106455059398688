import React, { Component } from "react";

export default class DataScienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg width="888" height="711" viewBox="0 0 888 711" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_2)">
      <path d="M444.936 604.807C384.768 604.807 326.463 583.945 279.953 545.775C233.443 507.605 201.607 454.49 189.87 395.479C178.132 336.468 187.218 275.212 215.581 222.15C243.943 169.087 289.827 127.501 345.415 104.476C401.002 81.4508 462.853 78.4123 520.429 95.8779C578.005 113.344 627.744 150.233 661.171 200.26C694.598 250.287 709.645 310.357 703.748 370.234C697.85 430.111 671.374 486.092 628.829 528.636C604.736 552.861 576.077 572.067 544.512 585.141C512.947 598.216 479.101 604.9 444.936 604.807V604.807ZM444.936 86.6781C302.639 86.6781 186.871 202.446 186.871 344.743C186.871 487.04 302.639 602.807 444.936 602.807C587.232 602.807 703 487.04 703 344.743C703 202.446 587.232 86.6781 444.936 86.6781V86.6781Z" fill="#CCCCCC"/>
      <path d="M541.5 643.807V381.807H346.5V643.807H541.5Z" fill="#2F2E41"/>
      <path d="M575 373.807H313V477.807H575V373.807Z" fill="#3F3D56"/>
      <path d="M575 489.807H313V593.807H575V489.807Z" fill="#3F3D56"/>
      <path d="M575 605.807H313V709.807H575V605.807Z" fill="#3F3D56"/>
      <path d="M575 417.904H313V433.904H575V417.904Z" fill={theme.imageHighlight}/>
      <path d="M575 533.904H313V549.904H575V533.904Z" fill={theme.imageHighlight}/>
      <path d="M575 649.904H313V665.904H575V649.904Z" fill={theme.imageHighlight}/>
      <path d="M524 394.807C527.314 394.807 530 392.121 530 388.807C530 385.494 527.314 382.807 524 382.807C520.686 382.807 518 385.494 518 388.807C518 392.121 520.686 394.807 524 394.807Z" fill={theme.imageHighlight}/>
      <path d="M545 394.807C548.314 394.807 551 392.121 551 388.807C551 385.494 548.314 382.807 545 382.807C541.686 382.807 539 385.494 539 388.807C539 392.121 541.686 394.807 545 394.807Z" fill={theme.imageHighlight}/>
      <path d="M566 394.807C569.314 394.807 572 392.121 572 388.807C572 385.494 569.314 382.807 566 382.807C562.686 382.807 560 385.494 560 388.807C560 392.121 562.686 394.807 566 394.807Z" fill={theme.imageHighlight}/>
      <path d="M524 508.807C527.314 508.807 530 506.121 530 502.807C530 499.494 527.314 496.807 524 496.807C520.686 496.807 518 499.494 518 502.807C518 506.121 520.686 508.807 524 508.807Z" fill={theme.imageHighlight}/>
      <path d="M545 508.807C548.314 508.807 551 506.121 551 502.807C551 499.494 548.314 496.807 545 496.807C541.686 496.807 539 499.494 539 502.807C539 506.121 541.686 508.807 545 508.807Z" fill={theme.imageHighlight}/>
      <path d="M566 508.807C569.314 508.807 572 506.121 572 502.807C572 499.494 569.314 496.807 566 496.807C562.686 496.807 560 499.494 560 502.807C560 506.121 562.686 508.807 566 508.807Z" fill={theme.imageHighlight}/>
      <path d="M524 622.807C527.314 622.807 530 620.121 530 616.807C530 613.494 527.314 610.807 524 610.807C520.686 610.807 518 613.494 518 616.807C518 620.121 520.686 622.807 524 622.807Z" fill={theme.imageHighlight}/>
      <path d="M545 622.807C548.314 622.807 551 620.121 551 616.807C551 613.494 548.314 610.807 545 610.807C541.686 610.807 539 613.494 539 616.807C539 620.121 541.686 622.807 545 622.807Z" fill={theme.imageHighlight}/>
      <path d="M566 622.807C569.314 622.807 572 620.121 572 616.807C572 613.494 569.314 610.807 566 610.807C562.686 610.807 560 613.494 560 616.807C560 620.121 562.686 622.807 566 622.807Z" fill={theme.imageHighlight}/>
      <path d="M888 708.807H0V710.807H888V708.807Z" fill="#3F3D56"/>
      <path d="M241.879 271.065V457.056C241.878 460.947 240.332 464.679 237.58 467.43C234.829 470.182 231.098 471.728 227.206 471.729H154.928C151.036 471.729 147.303 470.184 144.55 467.432C141.798 464.681 140.251 460.948 140.25 457.056V271.065C140.251 267.173 141.798 263.44 144.55 260.689C147.303 257.937 151.036 256.392 154.928 256.392H163.696C163.265 257.451 163.102 258.599 163.219 259.736C163.336 260.873 163.731 261.964 164.368 262.913C165.006 263.861 165.866 264.639 166.875 265.177C167.883 265.715 169.008 265.997 170.151 265.999H211.359C212.502 265.997 213.627 265.715 214.636 265.177C215.644 264.639 216.505 263.861 217.142 262.913C217.78 261.964 218.174 260.873 218.292 259.736C218.409 258.599 218.245 257.451 217.814 256.392H227.206C231.098 256.393 234.829 257.939 237.58 260.691C240.332 263.442 241.878 267.174 241.879 271.065V271.065Z" fill="#E6E6E6"/>
      <path d="M237.064 291.91H145.064V419.91H237.064V291.91Z" fill="white"/>
      <path d="M207.905 379.927H171.186V382.025H207.905V379.927Z" fill="#E6E6E6"/>
      <path d="M227.966 387.632H171.149V389.73H227.966V387.632Z" fill={theme.imageHighlight}/>
      <path d="M215.737 394.89H171.149V396.988H215.737V394.89Z" fill="#E6E6E6"/>
      <path d="M197.377 402.149H171.149V404.247H197.377V402.149Z" fill="#E6E6E6"/>
      <path d="M210.491 409.407H171.149V411.506H210.491V409.407Z" fill="#E6E6E6"/>
      <path d="M158.73 379.324H154.533V383.52H158.73V379.324Z" fill="#E6E6E6"/>
      <path d="M158.73 386.582H154.533V390.779H158.73V386.582Z" fill={theme.imageHighlight}/>
      <path d="M158.73 393.841H154.533V398.038H158.73V393.841Z" fill="#E6E6E6"/>
      <path d="M158.73 401.1H154.533V405.296H158.73V401.1Z" fill="#E6E6E6"/>
      <path d="M158.73 408.358H154.533V412.555H158.73V408.358Z" fill="#E6E6E6"/>
      <path d="M209.748 307.91H172.751V344.908H209.748V307.91Z" fill="#E6E6E6"/>
      <path d="M566.994 0.139404H321.111V157.614H566.994V0.139404Z" fill="#E6E6E6"/>
      <path d="M543.508 44.1953H339.647V121.231H543.508V44.1953Z" fill="white"/>
      <path d="M425.716 57.5418H376.789V60.3376H425.716V57.5418Z" fill="#E6E6E6"/>
      <path d="M452.446 67.8073H376.74V70.6031H452.446V67.8073Z" fill={theme.imageHighlight}/>
      <path d="M436.151 77.4789H376.74V80.2748H436.151V77.4789Z" fill="#E6E6E6"/>
      <path d="M411.688 87.1506H376.74V89.9464H411.688V87.1506Z" fill="#E6E6E6"/>
      <path d="M429.162 96.8222H376.74V99.618H429.162V96.8222Z" fill="#E6E6E6"/>
      <path d="M360.193 56.7378H354.601V62.3294H360.193V56.7378Z" fill="#E6E6E6"/>
      <path d="M360.193 66.4094H354.601V72.0011H360.193V66.4094Z" fill={theme.imageHighlight}/>
      <path d="M360.193 76.0811H354.601V81.6727H360.193V76.0811Z" fill="#E6E6E6"/>
      <path d="M360.193 85.7527H354.601V91.3443H360.193V85.7527Z" fill="#E6E6E6"/>
      <path d="M360.193 95.4243H354.601V101.016H360.193V95.4243Z" fill="#E6E6E6"/>
      <path d="M533.504 53.8475H483.445V103.906H533.504V53.8475Z" fill="#E6E6E6"/>
      <path d="M566.889 0H321.006V10.446H566.889V0Z" fill={theme.imageHighlight}/>
      <path d="M328.768 7.43743C329.926 7.43743 330.865 6.49864 330.865 5.34057C330.865 4.18251 329.926 3.24371 328.768 3.24371C327.61 3.24371 326.671 4.18251 326.671 5.34057C326.671 6.49864 327.61 7.43743 328.768 7.43743Z" fill="white"/>
      <path d="M336.117 7.43743C337.275 7.43743 338.214 6.49864 338.214 5.34057C338.214 4.18251 337.275 3.24371 336.117 3.24371C334.959 3.24371 334.02 4.18251 334.02 5.34057C334.02 6.49864 334.959 7.43743 336.117 7.43743Z" fill="white"/>
      <path d="M343.466 7.43743C344.624 7.43743 345.563 6.49864 345.563 5.34057C345.563 4.18251 344.624 3.24371 343.466 3.24371C342.308 3.24371 341.369 4.18251 341.369 5.34057C341.369 6.49864 342.308 7.43743 343.466 7.43743Z" fill="white"/>
      <path d="M731.648 294.807H671.648V325.807H731.648V294.807Z" fill="#E6E6E6"/>
      <path d="M731.648 405.807H671.648V436.807H731.648V405.807Z" fill="#E6E6E6"/>
      <path d="M701.648 415.807C729.262 415.807 751.648 393.422 751.648 365.807C751.648 338.193 729.262 315.807 701.648 315.807C674.033 315.807 651.648 338.193 651.648 365.807C651.648 393.422 674.033 415.807 701.648 415.807Z" fill="#E6E6E6"/>
      <path d="M701.648 404.807C723.187 404.807 740.648 387.347 740.648 365.807C740.648 344.268 723.187 326.807 701.648 326.807C680.109 326.807 662.648 344.268 662.648 365.807C662.648 387.347 680.109 404.807 701.648 404.807Z" fill="white"/>
      <path d="M719.123 355.122H688.877V356.85H719.123V355.122Z" fill="#E2E2E2"/>
      <path d="M735.648 361.468H688.846V363.196H735.648V361.468Z" fill={theme.imageHighlight}/>
      <path d="M725.575 367.447H688.846V369.175H725.575V367.447Z" fill="#E2E2E2"/>
      <path d="M710.451 373.426H688.846V375.154H710.451V373.426Z" fill="#E2E2E2"/>
      <path d="M721.254 379.405H688.846V381.133H721.254V379.405Z" fill="#E2E2E2"/>
      <path d="M678.617 354.625H675.16V358.082H678.617V354.625Z" fill="#E2E2E2"/>
      <path d="M678.617 360.604H675.16V364.061H678.617V360.604Z" fill={theme.imageHighlight}/>
      <path d="M678.617 366.583H675.16V370.04H678.617V366.583Z" fill="#E2E2E2"/>
      <path d="M678.617 372.562H675.16V376.019H678.617V372.562Z" fill="#E2E2E2"/>
      <path d="M678.617 378.541H675.16V381.998H678.617V378.541Z" fill="#E2E2E2"/>
      </g>
      <defs>
      <clipPath id="clip0_1_2">
      <rect width="888" height="710.807" fill="white"/>
      </clipPath>
      </defs>
      </svg>      
    );
  }
}
